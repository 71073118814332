// index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

// Uncomment the following line if you decide to use a service worker in the future
// import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement,
);

// CommentComponent.tsx
import React from 'react';
import { BiSolidChevronsUp } from 'react-icons/bi';
import { ImCross, ImPlus } from 'react-icons/im';
import CharacterCounter from './characterCounter';
import useCommentSystemPresentation from '../useCommentSystemPresentation';
import {
  Upvotes,
  CommentsData,
  OpenReplies,
  HandleDeleteComment,
  HandleDeleteReply,
  HandleUpvote,
  HandleAddReply,
} from '../__constants__/comment.types';
import { Button, Form } from 'react-bootstrap';

interface CommentComponentProps {
  commentId: string;
  commentsData: CommentsData;
  displayName: string;
  handleDeleteComment: HandleDeleteComment;
  handleDeleteReply: HandleDeleteReply;
  handleUpvote: HandleUpvote;
  handleAddReply: HandleAddReply;
  isAnonymous: boolean;
  openReplies: OpenReplies;
  setOpenReplies: React.Dispatch<React.SetStateAction<OpenReplies>>;
  showReplyBox: { [key: string]: boolean };
  setShowReplyBox: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  newReply: { [key: string]: string };
  setNewReply: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
}

export const CommentComponent: React.FC<CommentComponentProps> = ({
  commentId,
  commentsData,
  displayName,
  handleDeleteComment,
  handleDeleteReply,
  handleUpvote,
  handleAddReply,
  isAnonymous,
  openReplies,
  setOpenReplies,
  showReplyBox,
  setShowReplyBox,
  newReply,
  setNewReply,
}) => {
  const { styles } = useCommentSystemPresentation();
  const comment = commentsData[commentId];
  const getUpvoteCount = (upvotes: Upvotes) => Object.keys(upvotes).length;

  return (
    <div key={commentId} style={styles.commentWrapper}>
      <div style={styles.commentText}>{comment.content}</div>
      <div style={styles.commentText}>By: {comment.author}</div>

      {!isAnonymous && (
        <div
          style={styles.upvoteButton}
          onClick={() => handleUpvote(commentId, displayName)}
        >
          <BiSolidChevronsUp />
          <span>({getUpvoteCount(comment.upvotes)})</span>
        </div>
      )}

      {displayName === comment.author && !isAnonymous && (
        <ImCross
          style={styles.deleteIcon}
          onClick={() => handleDeleteComment(commentId)}
        />
      )}

      {!openReplies[commentId]?.isOpen ? (
        <Button
          style={styles.touchableText}
          onClick={() =>
            setOpenReplies({
              ...openReplies,
              [commentId]: { isOpen: true, shownReplies: 5 },
            })
          }
        >
          View Replies
        </Button>
      ) : (
        <>
          {comment.replies &&
            Object.keys(comment.replies)
              .slice(0, openReplies[commentId]?.shownReplies)
              .map((replyId) => {
                const reply = comment.replies[replyId];
                return (
                  <div key={replyId} style={styles.replyWrapper}>
                    <div style={styles.commentText}>{reply.content}</div>
                    <div style={styles.commentText}>By: {reply.author}</div>
                    {displayName === reply.author && !isAnonymous && (
                      <ImCross
                        style={styles.deleteIcon}
                        onClick={() => handleDeleteReply(commentId, replyId)}
                      />
                    )}
                  </div>
                );
              })}
          {!isAnonymous && showReplyBox[commentId] ? (
            <div>
              <Form.Control
                as='textarea'
                value={newReply[commentId] || ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNewReply({ ...newReply, [commentId]: e.target.value })
                }
                placeholder='What do you think?'
                maxLength={250}
                style={styles.replyBox}
              />
              <div style={styles.replyBox}>
                <CharacterCounter
                  currentCount={(newReply[commentId] || '').length}
                  limit={250}
                />
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => handleAddReply(commentId)}
                >
                  Reply
                </Button>
                <Button
                  variant='secondary'
                  size='sm'
                  onClick={() =>
                    setShowReplyBox({ ...showReplyBox, [commentId]: false })
                  }
                >
                  Hide
                </Button>
              </div>
            </div>
          ) : (
            !isAnonymous && (
              <div
                onClick={() =>
                  setShowReplyBox({ ...showReplyBox, [commentId]: true })
                }
                style={styles.touchableDiv}
              >
                <ImPlus style={styles.primaryColor} />
                <div style={styles.addReply}>Reply</div>
              </div>
            )
          )}
          <Button
            style={styles.touchableText}
            onClick={() =>
              setOpenReplies({
                ...openReplies,
                [commentId]: { isOpen: false, shownReplies: 5 },
              })
            }
          >
            Hide Replies
          </Button>
        </>
      )}
    </div>
  );
};

import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import {
  BsFillHouseDoorFill,
  BsPeopleFill,
  BsFillBellFill,
} from 'react-icons/bs';
import { MdSettings } from 'react-icons/md';
import { IoMdMail } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import '../../../App.scss';
import { useAuth } from '../../../contexts/AuthContext';
import useNavbarFullPresentation from './useNavbarFullPresentation';
import { StyledLink } from './navbarFull.styles';

interface LogoutBtnProps {
  onLogout: () => void;
}

export default function NavbarFull() {
  console.log('NavbarFull component is being rendered');
  const { logout, user } = useAuth();
  const { styles } = useNavbarFullPresentation();

  const [error, setError] = useState(''); // <-- Declared the error state variable

  // console.log('User object:', user);
  // console.log('Display name:', user?.displayName);

  const navigate = useNavigate();

  const LogoutBtn = ({ onLogout }: LogoutBtnProps) => (
    <Button variant='disabled' className='btn btn-secondary' onClick={onLogout}>
      Sign In
    </Button>
  );

  async function handleLogout() {
    setError('');
    try {
      await logout();
      navigate('/main');
    } catch (err) {
      setError('Failed to Log Out');
    }
  }

  return (
    <Container style={styles.container}>
      {error && <p style={{ color: 'red' }}>{error}</p>}{' '}
      {/* <-- Displaying the error message */}
      <Row>
        <Col fluid style={styles.col1}>
          <div>
            <h4>
              <StyledLink to='/'>
                <BsFillHouseDoorFill />
                <span
                  className='d-lg-inline-block d-none'
                  style={{ marginLeft: '10px' }}
                >
                  Home
                </span>
              </StyledLink>
            </h4>
          </div>
          {user && !user.isAnonymous && (
            <>
              <div style={{ paddingTop: '20%' }}>
                <h4>
                  <StyledLink to='#'>
                    <IoMdMail />
                    <span
                      className='d-lg-inline-block d-none'
                      style={{ marginLeft: '10px' }}
                    >
                      Inbox
                    </span>
                  </StyledLink>
                </h4>
              </div>
              <div style={{ paddingTop: '20%' }}>
                <h4>
                  <StyledLink to='#'>
                    <BsFillBellFill />
                    <span
                      className='d-lg-inline-block d-none'
                      style={{ marginLeft: '10px' }}
                    >
                      Alerts
                    </span>
                  </StyledLink>
                </h4>
              </div>
              <div style={{ paddingTop: '20%' }}>
                <h4>
                  <StyledLink to='#'>
                    <BsPeopleFill />
                    <span
                      className='d-lg-inline-block d-none'
                      style={{ marginLeft: '10px' }}
                    >
                      Following
                    </span>
                  </StyledLink>
                </h4>
              </div>
              <div style={{ paddingTop: '20%' }}>
                <h4>
                  <StyledLink to='/update-profile'>
                    <MdSettings />
                    <span
                      className='d-lg-inline-block d-none'
                      style={{ marginLeft: '10px' }}
                    >
                      Settings
                    </span>
                  </StyledLink>
                </h4>
              </div>
            </>
          )}
          {!user ||
            (user.isAnonymous && (
              <div
                style={{ position: 'absolute', bottom: '10px', left: '10px' }}
              >
                <LogoutBtn onLogout={handleLogout} />
              </div>
            ))}
        </Col>
      </Row>
    </Container>
  );
}

/* eslint-disable react/no-unknown-property */
import React, { useRef, useState, useEffect } from 'react';
import { useLoader, useThree } from '@react-three/fiber';
import EarthLights from '../assets/earthLights.jpg';
import { TextureLoader, Mesh } from 'three';
import { ThreeEvent } from '@react-three/fiber';

interface EarthProps {
  // Define the properties of the props object
}

export default function Earth(props: EarthProps) {
  // This reference gives us direct access to the THREE.Mesh object
  // Made the ref nullable
  const ref = useRef<Mesh | null>(null) as any;
  const { gl } = useThree();

  const [nightMap] = useLoader(TextureLoader, [EarthLights]);
  const [isDragging, setIsDragging] = useState(false);
  const [lastX, setLastX] = useState(0);

  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    setIsDragging(true);
    setLastX(event.nativeEvent.clientX);
  };

  const handlePointerUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const animate = () => {
      if (ref.current) {
        ref.current.rotation.y += 0.0001; // Adjust the rotation speed here
      }
      requestAnimationFrame(animate);
    };
    animate();
  }, []);

  useEffect(() => {
    const handlePointerMove = (event: PointerEvent) => {
      if (isDragging) {
        const deltaX = event.clientX - lastX;
        if (ref.current) {
          ref.current.rotation.y += deltaX * 0.01;
        }
        setLastX(event.clientX);
      }
    };

    if (gl.domElement) {
      gl.domElement.addEventListener('pointermove', handlePointerMove);
      gl.domElement.addEventListener('pointerup', handlePointerUp);
      gl.domElement.addEventListener('pointercancel', handlePointerUp);
    }

    return () => {
      if (gl.domElement) {
        gl.domElement.removeEventListener('pointermove', handlePointerMove);
        gl.domElement.removeEventListener('pointerup', handlePointerUp);
        gl.domElement.removeEventListener('pointercancel', handlePointerUp);
      }
    };
  }, [gl.domElement, isDragging, lastX]);

  return (
    <mesh
      {...props}
      ref={ref}
      position={[0, 0, -10]} // Adjust the Earth model's position
      scale={1.5} // Increase the scale size when not clicked
      onPointerDown={handlePointerDown}
    >
      <sphereGeometry attach='geometry' args={[5, 32, 32]} />
      <meshPhongMaterial isMaterial={EarthLights} />
      <meshStandardMaterial
        map={nightMap}
        roughnessMap={nightMap}
        attach='material'
      />
    </mesh>
  );
}

import React, { useEffect, useState, ChangeEvent } from 'react';
import '../../../App.scss';
import {
  upload,
  useAuth,
  AuthContextType,
} from '../../../contexts/AuthContext';
import { Button, Container, Form } from 'react-bootstrap';

interface ProfilePictureProps {}

const ProfilePicture: React.FC<ProfilePictureProps> = () => {
  const { user } = useAuth() as AuthContextType;
  const [image, setImage] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState(
    'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg',
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type;

      // Check if the file is a JPEG, JPG, or PNG
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/jpg' ||
        fileType === 'image/png'
      ) {
        setImage(file);
      } else {
        alert('Please upload a JPEG, JPG, or PNG file.');
      }
    }
  };

  const handleSubmit = async () => {
    if (!image || !user) {
      return;
    }
    setLoading(true);
    try {
      await upload(image, user, setLoading);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user && user.photoURL) {
      setPhotoURL(user.photoURL);
    }
  }, [user]);

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '2%',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '5%',
  };

  return (
    <>
      <div style={containerStyle}>
        <img src={photoURL} alt='Avatar' className='avatar' />
      </div>
      <Container style={containerStyle}>
        <Form.Group controlId='formFile' className='mb-3'>
          <Form.Control type='file' isValid={!!image} onChange={handleChange} />
        </Form.Group>
      </Container>
      <div style={buttonContainerStyle}>
        <Button disabled={loading || !image} onClick={handleSubmit}>
          Upload
        </Button>
      </div>
    </>
  );
};

export default ProfilePicture;

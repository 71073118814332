// SignupForm.tsx
import React, { useState } from "react";
import { Alert, Button, Card, Form, InputGroup } from "react-bootstrap";

export default function SignupForm({
  onSubmit,
  emailRef,
  passwordRef,
  confirmPasswordRef,
  usernameRef,
  error,
  loading,
}: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  emailRef: React.RefObject<HTMLInputElement>;
  passwordRef: React.RefObject<HTMLInputElement>;
  confirmPasswordRef: React.RefObject<HTMLInputElement>;
  usernameRef: React.RefObject<HTMLInputElement>;
  error: string;
  loading: boolean;
}) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Card
      className="card bg-light"
      style={{
        borderRadius: "30px",
        minHeight: "23rem",
        width: "20rem",
        zIndex: "1",
      }}
    >
      <Card.Body>
        <h2 className="text-center mb-4">Sign Up</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={onSubmit}>
          <Form.Group id="username">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" ref={usernameRef} required />
          </Form.Group>
          <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required />
          </Form.Group>
          <Form.Group id="password">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                ref={passwordRef}
                required
              />
              <InputGroup.Text>
                <Button
                  variant="outline-secondary"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group id="password-confirm">
            <Form.Label>Password Confirmation</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                ref={confirmPasswordRef}
                required
              />
              <InputGroup.Text>
                <Button
                  variant="outline-secondary"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <br />
          <Button
            disabled={loading}
            className="w-100"
            variant="primary"
            type="submit"
            style={{ borderRadius: "30px" }}
          >
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

//AuthContext.tsx
import {
  reload,
  updateProfile,
  updateEmail,
  updatePassword,
  User,
  signInAnonymously,
} from 'firebase/auth';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useContext, useState, useEffect } from 'react';
import { auth, storage } from '../firebase.js';

export const AuthContext = React.createContext<AuthContextType | null>(null);

export interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  signup: (email: string, password: string, username: string) => Promise<any>;
  forgotPassword: (email: string) => Promise<void>;
  updateUserEmail: (email: string) => Promise<void>;
  updateUserPassword: (password: string) => Promise<void>;
  updateUsername: (username: string) => Promise<void>;
  loginAsGuest: () => Promise<void>;
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  async function signup(email: string, password: string, username: string) {
    const result = await auth.createUserWithEmailAndPassword(email, password);
    if (result.user) {
      await updateProfile(result.user, { displayName: username });
      const refreshedUser = auth.currentUser; // Refetch the user
      // console.log('Updated displayName:', refreshedUser?.displayName);
      setUser(refreshedUser as User | null);
    }
    return result;
  }

  function login(email: string, password: string): Promise<void> {
    return auth.signInWithEmailAndPassword(email, password).then(() => {});
  }

  function logout() {
    return auth.signOut();
  }

  function forgotPassword(email: string) {
    return auth.sendPasswordResetEmail(email);
  }

  async function updateUserEmail(email: string) {
    if (user) {
      await updateEmail(user, email);
      await reload(user);
      setUser(auth.currentUser as User | null);
    }
  }

  async function updateUserPassword(password: string) {
    if (user) {
      await updatePassword(user, password);
      await reload(user);
      setUser(auth.currentUser as User | null);
    }
  }

  async function updateUsername(username: string) {
    if (user) {
      await updateProfile(user, { displayName: username });
      await reload(user);
      setUser(auth.currentUser as User | null);
    }
  }

  function loginAsGuest(): Promise<void> {
    return signInAnonymously(auth).then(() => {});
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user as User | null);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value: AuthContextType = {
    user,
    login,
    logout,
    signup,
    forgotPassword,
    updateUserEmail,
    updateUserPassword,
    updateUsername,
    loginAsGuest,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export async function upload(
  file: File,
  user: User | null,
  setLoad: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const fileRef = ref(storage, `${auth.currentUser?.uid}.png`);
  setLoad(true);

  // Upload the file
  await uploadBytes(fileRef, file);

  const photoURL = await getDownloadURL(fileRef);
  if (user) {
    await updateProfile(user, { photoURL });
    setLoad(false);
    alert('Uploaded');
  }
}

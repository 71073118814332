//useEventDisplayContent.ts
import { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { onValue, ref } from 'firebase/database';
import { Article, ArticleContent } from './constants/eventDisplay.types';

export default function useEventDisplayContent(articleId: string) {
  const [article, setArticle] = useState<Article | null>(null);
  const [content, setContent] = useState<ArticleContent | null>(null);

  useEffect(() => {
    const articleRef = ref(db, `articles/${articleId}`);
    const unsubscribe = onValue(
      articleRef,
      (snapshot) => {
        const articleData = snapshot.val() as Article;
        if (articleData) {
          setArticle(articleData);
          setContent(
            (articleData.content as unknown as ArticleContent) || null,
          );
        }
      },
      (error) => {
        console.error('Error reading data: ', error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [articleId]);

  return {
    article,
    content,
  };
}

//feedMain.tsx
import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import '../../../App.scss';
import useFeedMainContent from './useFeedMainContent';
import useFeedMainPresentation from './useFeedMainPresentation';
import { useNavigate } from 'react-router-dom';

export default function FeedMain() {
  const { articles } = useFeedMainContent();
  const { styles } = useFeedMainPresentation();
  const navigate = useNavigate();

  return (
    <Container fluid className={`p-0`} style={styles.container}>
      <Row>
        <Col>
          <div>
            {Object.entries(articles).map(([id, article]) => (
              <Card
                key={id}
                className='card bg-light mb-3'
                onClick={() => navigate(`/event/${article.id}`)}
              >
                <Card.Body>
                  <Card.Title>{article.title}</Card.Title>
                  <Card.Text>
                    <p>{article.content.event}</p>
                    <small className='text-muted'>
                      By {article.author} on {article.date}
                    </small>
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

/* eslint-disable react/no-unknown-property */
// LandingPage.tsx
import React, { Suspense } from "react";
import { Card, Button, Container, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import {
  AppleLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Earth from "../../core-ui/earthLogin";
import "./preLogin.scss";
import { useAuth } from "../../../contexts/AuthContext";
import { useAlert } from "../../../contexts/AlertContext";
import { logEvent } from "@firebase/analytics";

const LogoTitle = () => (
  <>
    <span className="text-black">Neg</span>
    <span className="text-blue">nan</span>
  </>
);

const EmailLoginButton = ({ onClick }: { onClick: () => void }) => (
  logEvent(getAuth(), "login", { method: "email" }),
  (
    <Button className="btn emailButton w-100" onClick={onClick}>
      Log in with Email
    </Button>
  )
);

const GuestLoginLink = ({ onClick }: { onClick: () => void }) => (
  <div className="mx-auto landing-page-guest-link">
    Continue without logging in?{" "}
    <Link to="#" onClick={onClick}>
      Guest
    </Link>
  </div>
);

const EarthCanvas = () => (
  <Canvas
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    }}
  >
    <Suspense fallback={null}>
      <ambientLight intensity={2} />
      <Earth />
    </Suspense>
  </Canvas>
);

export default function LandingPage() {
  const navigate = useNavigate();
  const auth = getAuth();
  const { loginAsGuest } = useAuth();
  const { showAlert } = useAlert();
  const googleAuthProvider = new GoogleAuthProvider();

  async function handleGuestLogin() {
    try {
      await loginAsGuest();
      logEvent(auth, "login", { method: "guest" });
      navigate("/welcome");
    } catch (error) {
      showAlert("Failed to log in as a guest.");
    }
  }

  function handleEmail() {
    navigate("/login");
  }

  // This code signs the user in with a Google account.
  // It uses the Google Sign-in API to get a Google credential,
  // and then it signs the user in using that credential.
  // It then redirects the user to the home page.
  function handleGoogleSignIn() {
    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        GoogleAuthProvider.credentialFromResult(result);
        navigate("/welcome");
      })
      .catch((error) => {
        const googleErrorCode = error.code;
        const googleErrorMessage = error.message;
        showAlert(googleErrorCode + ": " + googleErrorMessage);
      });
  }

  return (
    <Container fluid className="p-0">
      <div className="earth-card-container">
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card className="bg-light text-center landing-page-card card-fixed-width">
              <Card.Body>
                <h2 className="mb-4">
                  <LogoTitle />
                </h2>
                <EmailLoginButton onClick={handleEmail} />
                <GoogleLoginButton onClick={handleGoogleSignIn} />
                <AppleLoginButton />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <GuestLoginLink onClick={handleGuestLogin} />
        <EarthCanvas />
      </div>
    </Container>
  );
}

import React, { ReactNode } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import FeedMain from '../../core-ui/MainFeed/feedMain';
import NavbarFull from '../../core-ui/Navigation/navbarFull';
import NavbarMini from '../../core-ui/Navigation/navbarMini';

const LogoTitle = () => (
  <>
    <span style={{ color: 'black', userSelect: 'none' }}>Neg</span>
    <span style={{ color: 'blue', userSelect: 'none' }}>nan</span>
  </>
);

// TODO: Will remove later
const TestComponent = () => <div>Sorry, This Feed Will Be Available Soon</div>;

interface DashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => (
  <Container
    fluid
    className='p-0 h-100 w-100 m-0'
    style={{ overflow: 'hidden' }}
  >
    <Row style={{ height: 'auto' }}>{children}</Row>
  </Container>
);

const Sidebar = () => (
  <Col
    xl={2}
    lg={3}
    md={4}
    sm={3}
    className='d-none d-sm-block bg-light border-right border-dark'
    style={{ borderRight: '2px solid black' }}
  >
    <h2>
      <LogoTitle />
    </h2>
    <NavbarFull />
  </Col>
);

const MainContent = () => (
  <Col
    xs={12}
    sm={9}
    md={8}
    lg={6}
    xl={6}
    className='bg-light'
    style={{ minHeight: '95vh' }}
  >
    <Tabs
      defaultActiveKey='usNews'
      id='uncontrolled-tab-example'
      className='justify-content-center'
    >
      <Tab eventKey='usNews' title='US Feed'>
        <div style={{ marginTop: 30, marginLeft: '5%' }}>
          <FeedMain />
        </div>
      </Tab>
      <Tab eventKey='worldNews' title='World Feed'>
        <div style={{ marginTop: 30 }}>
          <TestComponent />
        </div>
      </Tab>
    </Tabs>
  </Col>
);

const SidePanel = () => (
  <Col
    xl={4}
    lg={3}
    md={0}
    sm={0}
    className='d-none d-sm-block bg-light'
    style={{ borderLeft: '2px solid black' }}
  ></Col>
);

export default function Dashboard() {
  return (
    <>
      <DashboardLayout>
        <Row className='bg-light'>
          <Sidebar />
          <MainContent />
          <SidePanel />
        </Row>
        <Row className='d-sm-none bg-light'>
          <NavbarMini />
        </Row>
      </DashboardLayout>
    </>
  );
}

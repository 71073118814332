import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function WelcomeScreen() {
  const navigate = useNavigate();
  const [showPerspective, setShowPerspective] = useState(false);

  const timeoutAmount = false ? 100000 : 3000; // set to true for testing

  useEffect(() => {
    const perspectiveTimeout = setTimeout(() => {
      setShowPerspective(true);
    }, 1000);

    const navigateTimeout = setTimeout(() => {
      navigate('/');
    }, timeoutAmount);

    return () => {
      clearTimeout(perspectiveTimeout);
      clearTimeout(navigateTimeout);
    };
  }, [navigate, timeoutAmount]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        cursor: 'pointer',
      }}
      onClick={() => navigate('/')}
    >
      <div style={{ height: '3rem' }}>
        <h3 style={{ margin: '0', padding: '0' }}>
          Don&apos;t Be A Negative Nancy
        </h3>
      </div>
      <div style={{ height: '1rem' }}>
        <h6
          style={{
            opacity: showPerspective ? 1 : 0,
            transition: 'opacity 1s linear',
            margin: 0,
            padding: 0,
            height: showPerspective ? 'auto' : 0,
          }}
        >
          Explore Other Viewpoints
        </h6>
      </div>
    </div>
  );
}

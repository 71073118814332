export default function FeedMainStyles() {
  return {
    container: {
      display: 'relative',
      height: '100%',
      width: '100%',
      margin: '0',
      padding: '0',
    },
  };
}

//navbarFull.styles.ts
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding-left: 5%;

  &:hover {
    color: blue;
  }
`;

export default function NavbarFullStyles() {
  return {
    alerts: {
      color: 'inherit',
      textDecoration: 'none',
      paddingLeft: '5%',
    },
    container: {
      height: '100vh',
    },
    col1: {
      flex: '1',
      flexDirection: 'row' as const,
      alignItems: 'center',
      height: '100vh',
      paddingLeft: '0',
      paddingRight: '0',
      paddingTop: '60%',
    },
    following: {
      color: 'inherit',
      textDecoration: 'none',
      paddingLeft: '5%',
    },
    home: {
      color: 'inherit',
      textDecoration: 'none',
      paddingLeft: '5%',
    },
    inbox: {
      color: 'inherit',
      textDecoration: 'none',
      paddingLeft: '5%',
    },
    setting: {
      color: 'inherit',
      textDecoration: 'none',
      paddingLeft: '5%',
    },
  };
}

//commentBox.tsx
import React from 'react';
import { Form } from 'react-bootstrap';
import { BiSolidCommentAdd } from 'react-icons/bi';
import CharacterCounter from './characterCounter';

interface CommentBoxProps {
  newComment: string;
  setNewComment: (comment: string) => void;
  handleAddComment: () => void;
}

export default function CommentBox({
  newComment,
  setNewComment,
  handleAddComment,
}: CommentBoxProps) {
  return (
    <div style={{ marginBottom: '5%' }}>
      <Form.Group className='bg-light'>
        <div style={{ position: 'relative' }}>
          <Form.Control
            as='textarea'
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder='What Is Your Viewpoint?'
            maxLength={250}
            style={{ paddingRight: '60px', paddingBottom: '20px' }} // Make space for the icon
          />
          <div
            onClick={handleAddComment}
            className='bg-primary' // Apply the bg-primary class
            style={{
              position: 'absolute',
              right: '5px', // Adjust as needed
              bottom: '5px', // Adjust as needed
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '5px', // Add padding
              borderRadius: '5px', // Optional: Round the corners
            }}
          >
            <span
              style={{
                fontSize: '1.0em',
                color: 'white',
                marginRight: '5px',
              }}
            >
              Post
            </span>
            <BiSolidCommentAdd style={{ fontSize: '1.0em', color: 'white' }} />
          </div>
        </div>
      </Form.Group>
      <CharacterCounter currentCount={newComment.length} limit={250} />
    </div>
  );
}

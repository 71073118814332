/* eslint-disable react/no-unknown-property */
// SignupScreen.tsx
import React, { useRef, useState, Suspense } from "react";
import { Container } from "react-bootstrap";
import Earth from "../../core-ui/earthLogin";
import { Canvas } from "@react-three/fiber";
import SignupForm from "./signupForm";
import { useAuth } from "../../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const CanvasContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function SignupScreen() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);

  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;
    const username = usernameRef.current?.value;

    if (!username) {
      return setError("Username must be provided");
    }

    if (password !== confirmPassword) {
      return setError("Passwords do not match");
    }

    if (!email || !password) {
      return setError("Email and password must be provided");
    }

    try {
      setError("");
      setLoading(true);
      await signup(email, password, username); // Add the username parameter here
      navigate("/");
    } catch (error) {
      console.log(error);
      setError("Failed to create an account");
    }
    setLoading(false);
  }

  return (
    <>
      <Container
        fluid
        className="p-0"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          margin: "0",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div>
              <CardWrapper>
                <SignupForm
                  onSubmit={handleSubmit}
                  emailRef={emailRef}
                  passwordRef={passwordRef}
                  confirmPasswordRef={confirmPasswordRef}
                  usernameRef={usernameRef}
                  error={error}
                  loading={loading}
                />
              </CardWrapper>
              <div
                style={{
                  textAlign: "center",
                  paddingTop: error ? "2rem" : "1rem",
                  color: "white",
                }}
              >
                Already have an account? <Link to="/login">Log In</Link>
              </div>
            </div>
          </div>

          <CanvasContainer>
            <Canvas>
              <Suspense fallback={null}>
                <ambientLight intensity={2} />
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                <Earth />
              </Suspense>
            </Canvas>
          </CanvasContainer>
        </div>
      </Container>
    </>
  );
}

export default SignupScreen;

// CommentSystem.tsx
import React, { useState } from 'react';
import CommentBox from './DiscussionComponents/commentBox';
import { CommentComponent } from './DiscussionComponents/commentComponent';
import { Col, Container, Row } from 'react-bootstrap';
import useCommentSystemContent from './useCommentSystemContent';

interface CommentProps {
  articleId: string;
  commentsData: any; // Strong typing should be used here.
  displayName: string;
}

export const CommentSystem: React.FC<CommentProps> = ({
  articleId,
  commentsData,
  displayName,
}) => {
  const {
    handleAddComment,
    handleDeleteComment,
    handleDeleteReply,
    handleAddReply,
    handleUpvote,
    sortedComments,
    newComment,
    setNewComment,
    newReply,
    setNewReply,
  } = useCommentSystemContent(articleId, displayName);

  const [openReplies, setOpenReplies] = useState<{
    [key: string]: { isOpen: boolean; shownReplies: number };
  }>({});

  const [showReplyBox, setShowReplyBox] = useState<{ [key: string]: boolean }>(
    {},
  );

  const isAnonymous = displayName === 'Anonymous';

  return (
    <Container style={{ marginTop: '10%' }}>
      <Row className='justify-content-center'>
        <Col xs={12} md={8} className='commentSystemWrapper'>
          {!isAnonymous ? (
            <CommentBox
              newComment={newComment}
              setNewComment={setNewComment}
              handleAddComment={handleAddComment}
            />
          ) : null}
          {sortedComments.map((commentId) => (
            <CommentComponent
              key={commentId}
              commentId={commentId}
              commentsData={commentsData}
              displayName={displayName}
              handleDeleteComment={handleDeleteComment}
              handleDeleteReply={handleDeleteReply}
              handleUpvote={handleUpvote}
              handleAddReply={handleAddReply}
              isAnonymous={isAnonymous}
              openReplies={openReplies}
              setOpenReplies={setOpenReplies}
              showReplyBox={showReplyBox}
              setShowReplyBox={setShowReplyBox}
              newReply={newReply}
              setNewReply={setNewReply}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

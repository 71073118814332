import React from 'react';

interface CharacterCounterProps {
  currentCount: number;
  limit: number;
}

const CharacterCounter: React.FC<CharacterCounterProps> = ({
  currentCount,
  limit,
}) => {
  return (
    <div>
      {currentCount} / {limit}
    </div>
  );
};

export default CharacterCounter;

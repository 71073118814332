//useCommentSystemContent.ts
import { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import {
  ref,
  set,
  push,
  runTransaction,
  onValue,
  remove,
} from 'firebase/database';
import { CommentsData } from './__constants__/content.types';

export default function useCommentSystemContent(
  articleId: string,
  displayName: string,
) {
  const [commentsData, setCommentsData] = useState<CommentsData | null>(null);
  const [newComment, setNewComment] = useState<string>('');
  const [newReply, setNewReply] = useState<{ [key: string]: string }>({});
  const [newRebuttal, setNewRebuttal] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const commentsRef = ref(db, `comments/${articleId}`);
    const unsubscribe = onValue(
      commentsRef,
      (snapshot) => {
        setCommentsData(snapshot.val());
      },
      (error) => {
        console.error('Error reading comments: ', error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [articleId]);

  const handleAddComment = () => {
    if (newComment.trim()) {
      const newCommentRef = push(ref(db, `comments/${articleId}`));
      set(newCommentRef, {
        content: newComment,
        author: displayName || 'Anonymous', // Use displayName or default to 'Anonymous'
        date: new Date().toISOString(),
        upvotes: { [displayName]: true }, // Initialize with the user's upvote
        flags: 0,
      });
      setNewComment('');
    }
  };

  const handleUpvote = (commentId: string, displayName: string) => {
    const commentUpvotesRef = ref(
      db,
      `comments/${articleId}/${commentId}/upvotes/${displayName}`,
    );
    set(commentUpvotesRef, true).catch((error) => {
      console.error('Error upvoting comment:', error);
    });
  };

  const handleDeleteComment = (commentId: string) => {
    const commentRef = ref(db, `comments/${articleId}/${commentId}`);
    remove(commentRef).catch((error) => {
      console.error('Error deleting comment:', error);
    });
  };

  const handleDeleteReply = (commentId: string, replyId: string) => {
    const replyRef = ref(
      db,
      `comments/${articleId}/${commentId}/replies/${replyId}`,
    );
    set(replyRef, null);
  };

  const handleAddReply = (commentId: string) => {
    if (newReply[commentId]?.trim()) {
      const newReplyRef = push(
        ref(db, `comments/${articleId}/${commentId}/replies`),
      );
      set(newReplyRef, {
        content: newReply[commentId],
        author: displayName || 'Anonymous', // Use displayName or default to 'Anonymous'
        date: new Date().toISOString(),
        upvotes: { [displayName]: true }, // Initialize with the user's upvote
        flags: 0,
      });
      setNewReply({ ...newReply, [commentId]: '' });
    }
  };

  const handleAddRebuttal = (commentId: string, replyId: string) => {
    if (newRebuttal[replyId]?.trim()) {
      const newRebuttalRef = ref(
        db,
        `comments/${articleId}/${commentId}/replies/${replyId}/rebuttals`,
      );
      set(newRebuttalRef, {
        content: newRebuttal[replyId],
        author: displayName || 'Anonymous',
        date: new Date().toISOString(),
        upvotes: { [displayName]: true }, // Initialize with the user's upvote
        flags: 0,
      });
      setNewRebuttal({ ...newRebuttal, [replyId]: '' });
      // console.log('New rebuttal ref:', newRebuttalRef); // Log the new rebuttal reference
      // console.log('New rebuttal content:', newRebuttal[replyId]); // Log the new rebuttal content
    }
  };

  const handleDeleteRebuttal = (
    commentId: string,
    replyId: string,
    rebuttalId: string,
  ) => {
    const rebuttalRef = ref(
      db,
      `comments/${articleId}/${commentId}/replies/${replyId}/rebuttals/${rebuttalId}`,
    );
    set(rebuttalRef, null);
  };

  const handleUpvoteRebuttal = (
    commentId: string,
    replyId: string,
    rebuttalId: string,
  ) => {
    const rebuttalUpvotesRef = ref(
      db,
      `comments/${articleId}/${commentId}/replies/${replyId}/rebuttals/${rebuttalId}/upvotes`,
    );
    runTransaction(rebuttalUpvotesRef, (currentUpvotes) => {
      if (currentUpvotes === null || currentUpvotes === undefined) {
        return 1;
      }
      return currentUpvotes + 1;
    }).catch((error) => {
      console.error('Error upvoting rebuttal:', error);
    });
  };

  const sortedComments = Object.keys(commentsData || {}).sort((a, b) => {
    const upvotesA = commentsData
      ? Object.keys(commentsData[a]?.upvotes || {}).length
      : 0;
    const upvotesB = commentsData
      ? Object.keys(commentsData[b]?.upvotes || {}).length
      : 0;
    return upvotesB - upvotesA;
  });

  return {
    commentsData,
    handleAddComment,
    handleDeleteComment,
    handleDeleteReply,
    handleDeleteRebuttal,
    handleAddReply,
    handleAddRebuttal,
    handleUpvoteRebuttal,
    handleUpvote,
    sortedComments,
    newComment,
    setNewComment,
    newReply,
    setNewReply,
    newRebuttal,
    setNewRebuttal,
  };
}

//useFeedMainContent.ts
import { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import '../../../App.scss';
import { onValue, ref } from 'firebase/database';
import { ContentPaths } from './constants/content.paths';

export default function useFeedMainContent() {
  const [articles, setArticles] = useState<any[]>([]);

  useEffect(() => {
    const articlesRef = ref(db, ContentPaths.ARTICLES);
    const unsubscribe = onValue(
      articlesRef,
      (snapshot) => {
        const articlesData = snapshot.val();
        const sortedArticles = Object.keys(articlesData)
          .map((key) => ({ id: key, ...articlesData[key] }))
          .sort((a, b) => parseInt(a.weight) - parseInt(b.weight));
        setArticles(sortedArticles);
      },
      (error) => {
        console.error('Error reading data: ', error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return {
    articles,
  };
}

// PrivateRoute.tsx
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth, AuthContextType } from '../contexts/AuthContext';

interface PrivateRouteProps {
  children: ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps): JSX.Element | null {
  const auth: AuthContextType | null = useAuth();
  const user = auth?.user ?? null;
  return user ? <>{children}</> : <Navigate to='/main' />;
}

export default PrivateRoute;

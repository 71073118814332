//eventDisplay.tsx
import React, { useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useEventDisplayContent from './useEventDisplayContent';
import { useParams, useNavigate } from 'react-router-dom';
import useEventDisplayPresentation from './useEventDisplayPresentation';
import {
  FaChevronLeft,
  FaChevronRight,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { CommentSystem } from '../CommentSystem/commentSystem';
import useCommentSystem from '../CommentSystem/useCommentSystemContent';
import { auth } from '../../../firebase';

export default function EventDisplay() {
  const { articleId } = useParams<{ articleId: string }>();
  const { article, content } = useEventDisplayContent(articleId || '');

  const { styles } = useEventDisplayPresentation();
  const navigate = useNavigate();

  const displayName = auth.currentUser?.displayName || 'Anonymous';
  const { commentsData } = useCommentSystem(articleId || '', displayName);

  // // Here is a very basic CommentComponent for testing
  // const CommentComponent = ({ articleId }: { articleId: string }) => {
  //   return <div>Back to {articleId}</div>;
  // };

  const contentKeys = useMemo(
    () => ['left', 'leftCenter', 'event', 'rightCenter', 'right'],
    [],
  );

  const [selectedItem, setSelectedItem] = useState(
    contentKeys.findIndex((key) => key === 'event'),
  );

  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      switch (event.keyCode) {
        case 37: // Left arrow
          setSelectedItem((current) => (current > 0 ? current - 1 : current));
          break;
        case 39: // Right arrow
          setSelectedItem((current) =>
            current < contentKeys.length - 1 ? current + 1 : current,
          );
          break;
        case 40: // Down arrow
          setShowComments(true);
          break;
        case 38: // Up arrow
          setShowComments(false);
          setSelectedItem(
            contentKeys.findIndex((key) => key === 'contentEvent'),
          );
          break;
        default:
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [contentKeys.length]);

  if (!articleId) {
    return <div>Error: Article ID not found in route.</div>;
  }

  if (!article) {
    return null;
  }
  return (
    <div style={{ ...styles.wrapper, overflowX: 'hidden' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: '10px',
        }}
      >
        <button style={styles.backButton} onClick={() => navigate('/')}>
          <FaChevronLeft size={24} />
        </button>
        {showComments && (
          <>
            <button
              style={styles.arrowButtonUp}
              onClick={() => {
                setShowComments(false);
                setSelectedItem(
                  contentKeys.findIndex((key) => key === 'event'),
                );
              }}
            >
              <FaChevronUp size={24} />
            </button>
            <CommentSystem
              articleId={articleId}
              commentsData={commentsData}
              displayName={displayName}
            />
          </>
        )}
      </div>
      <div style={styles.container}>
        {!showComments && (
          <div
            style={{
              ...styles.carouselContainer,
              position: 'absolute',
              width: '100%',
              zIndex: 0,
            }}
          >
            <button
              style={styles.arrowButtonLeft}
              onClick={() =>
                setSelectedItem((current) =>
                  current > 0 ? current - 1 : current,
                )
              }
            >
              <FaChevronLeft size={24} />
            </button>
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              selectedItem={selectedItem}
            >
              {contentKeys.map((key, index) => (
                <div key={index} style={styles.carouselItem}>
                  <p>{content && content[key]}</p>
                  <p style={styles.legend}>{key}</p>
                </div>
              ))}
            </Carousel>
            <button
              style={styles.arrowButtonRight}
              onClick={() =>
                setSelectedItem((current) =>
                  current < contentKeys.length - 1 ? current + 1 : current,
                )
              }
            >
              <FaChevronRight size={24} />
            </button>
          </div>
        )}
        {!showComments && (
          <>
            <button
              style={styles.arrowButtonDown}
              onClick={() => setShowComments(true)}
            >
              <p
                style={{
                  position: 'absolute',
                  bottom: '30px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontSize: '20px',
                }}
              >
                Viewpoints
              </p>
              <FaChevronDown size={24} />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

//AlertContext.tsx
import React, { createContext, useState, ReactNode, useContext } from 'react';

interface AlertContextType {
  alertMessage: string;
  showAlert: (message: string) => void;
  hideAlert: () => void;
}

export const AlertContext = createContext<AlertContextType | null>(null);

export function useAlert(): AlertContextType {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
}

interface AlertProps {
  message: string;
}

export const Alert: React.FC<AlertProps> = ({ message }) => (
  <div className='alert'>{message}</div>
);

interface AlertProviderProps {
  children: ReactNode;
}

export default function AlertProvider({
  children,
}: AlertProviderProps): JSX.Element {
  const [alertMessage, setAlertMessage] = useState<string>('');

  function showAlert(message: string) {
    setAlertMessage(message);
  }

  function hideAlert() {
    setAlertMessage('');
  }

  return (
    <AlertContext.Provider value={{ alertMessage, showAlert, hideAlert }}>
      {children}
      {alertMessage && <Alert message={alertMessage} />}
    </AlertContext.Provider>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import '../../../App.scss';
import {
  BsFillHouseDoorFill,
  BsPeopleFill,
  BsFillBellFill,
} from 'react-icons/bs';
import { IoMdMail } from 'react-icons/io';
import { MdSettings } from 'react-icons/md';

export default function NavbarMini() {
  return (
    <div
      className='navmini d-flex align-items-center'
      style={{
        justifyContent: 'space-between', // Switched from justify-content-around
        padding: '0 10%', // Padding on left and right to keep icons centered
        backgroundColor: '#f8f9fa',
        bottom: '0px',
        width: '100%',
        height: '3rem',
        borderTop: '1px solid #000',
      }}
    >
      <Link to='#'>
        <IoMdMail className='link' />
      </Link>
      <Link to='#'>
        <BsFillBellFill className='link' />
      </Link>
      <Link to='/'>
        <BsFillHouseDoorFill className='link' />
      </Link>
      <Link to='#'>
        <BsPeopleFill className='link' />
      </Link>
      <Link to='/update-profile'>
        <MdSettings className='link' />
      </Link>
    </div>
  );
}

import { CSSProperties } from 'react';

const CommentSystemStyles: { [key: string]: CSSProperties } = {
  commentSystemWrapper: {
    top: '10%',
  },
  commentText: {
    marginTop: '1%',
  },
  commentWrapper: {
    position: 'relative',
    backgroundColor: '#f2f2f2',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '20px',
  },
  deleteIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
    color: '#000000',
    fontSize: '1.0rem',
  },
  upvoteButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  touchableDiv: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '5%',
    marginLeft: '10px',
  },
  addReply: {
    fontSize: '0.8rem',
    color: '#0d6efd',
    marginLeft: '10px',
  },
  primaryColor: {
    color: '#0d6efd',
  },
  touchableText: {
    background: 'none',
    border: 'none',
    color: '#000000',
    cursor: 'pointer',
    textDecoration: 'underline',
    outline: 'none',
    position: 'absolute',
    right: '10px',
    bottom: '10px',
  },
  replyBox: {
    width: '75%',
    marginLeft: '35px',
    marginTop: '2%',
  },
  replyWrapper: {
    borderTop: '1px solid #000',
    width: '75%',
    marginLeft: '35px',
    position: 'relative',
    marginTop: '5%',
  },
  rebuttalWrapper: {
    borderTop: '1px solid #000',
    marginLeft: '35px',
    marginTop: '30px',
  },
};

export default CommentSystemStyles;
